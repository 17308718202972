import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import Hospital from "../assets/images/diabeat_it_web_portal_background.jpeg";
import Logo from "../assets/images/logo.png";
import "../assets/css/common.css";
// import { useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";

// styles
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        top: 0,
        left: 0,
      },
    },
    logotypeContainer: {
      backgroundImage: `url(${Hospital})`,
      backgroundSize: "cover",
      backgroundRepeat: "no repeat",
      width: "60%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "50%",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    formContainer: {
      width: "40%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "60%",
      },
    },
    form: {
      width: 320,
      padding: 24,
      justifyContent: "center",
      alignItems: "center",
      background: "#F1F1F1",
      borderRadius: 12,
      marginTop: 24,
      // [theme.breakpoints.down("md")]: {
      //   marginTop: 12,
      //   background: "#f8f9fa",
      //   borderRadius: 0,
      // },
    },
    greeting: {
      fontWeight: 500,
      marginTop: theme.spacing(4),
      color: "#28A745",
      letterSpacing: 3,
      fontSize: 24,
    },
    subGreeting: {
      fontWeight: 500,
      textAlign: "center",
      marginTop: theme.spacing(2),
    },
    title: {
      color: "#36567F",
      fontWeight: "700",
      letterSpacing: 1,
      fontSize: 24,
      marginBottom: 8,
      textAlign: "center",
    },
    subTitle: {
      color: "#36567F",
      letterSpacing: 1,
      fontSize: 14,
      marginBottom: 8,
      padding: "20px 0px 0px 0px",
    },
    errorMessage: {
      textAlign: "center",
      fontSize: 12,
      fontWeight: "600",
      color: "red",
      padding: "20px 0px 0px 0px",
    },
    textFieldUnderline: {
      "&:before": {
        borderBottomColor: "#747272",
      },
      "&:after": {
        borderBottomColor: "#36567F",
      },
      "&:hover:before": {
        borderBottomColor: "#36567F !important",
      },
    },
    textField: {
      borderBottomColor: "#747272",
    },
    faFormButton: {
      width: "100%",
      marginTop: theme.spacing(4),
      textTransform: "none ",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    copyright: {
      bottom: theme.spacing(4),
      paddingBottom: 4,
      marginTop: 24,
      color: "#2B2828",
      whiteSpace: "nowrap",
      textAlign: "center",
      position: "absolute",
    },
    verifyBtn: {
      backgroundColor: "#FF6B00",
      color: "#FFF",
      textTransform: "none !Important",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#365680",
      },
    },
    otpContainer: {
      padding: "20px 0px",
    },
    otpField: {
      color: "#FF6B00",
      padding: 10,
      margin: 5,
      fontSize: 14,
      fontWeight: "600",
      border: "2px solid #365680 !important",
    },
  })
);

const FA = () => {
  const classes = useStyles();
  const [code, setCode] = useState("");

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}></div>
      <div className={classes.formContainer}>
        <img src={Logo} className="logo" alt="logo" />
        <div className={classes.form}>
          <Typography className={classes.title}>Diabeat-it Verification</Typography>
          <Typography className={classes.subTitle} variant="h3">
            We have sent you the 6-digit code. Please enter it to confirm your
            identity.
          </Typography>
          <>
            <OtpInput
              value={code}
              onChange={(e) => {
                setCode(e.trim());
                console.log(e);
              }}
              containerStyle={classes.otpContainer}
              inputStyle={classes.otpField}
              errorStyle={{ border: "2px solid #FF3333 !important" }}
              numInputs={6}
              separator={<span>-</span>}
              isInputNum={true}
              hasErrored={true}
              shouldAutoFocus={true}
            />

            <div className={classes.faFormButton}>
              <Button
                disabled={code.length === 0}
                className={classes.verifyBtn}
                fullWidth
                variant="contained"
                size="large"
                disableTouchRipple={true}
                disableFocusRipple={true}
              >
                Verify
              </Button>
            </div>
          </>
        </div>
        <Typography className={classes.copyright}>
            diabeat-it-app.ca © {new Date().getFullYear()}
        </Typography>
      </div>
    </Grid>
  );
};

export default FA;
