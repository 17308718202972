import {
  Button,
  makeStyles,
  Typography,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import NavDrawer from "./NavDrawer";
import MaterialDatatable from "material-datatable";
import axios from "axios";
import moment from 'moment'

const SPACED_DATE_FORMAT = "DD MMM YYYY";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: theme.spacing(6),
    boxSizing: "initial",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    margin: `${theme.spacing(0)} auto`,
    paddingTop: theme.spacing(4),
    justifyContent: "space-around",
    alignItems: "center",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  addBtn: {
    background: "#FF6B00 !important",
    color: "#fff",
    cursor: "pointer",
    letterSpacing: 2,
    "&:hover": {
      background: "#36567F !important",
    },
  },
  allPostTitle: {
    fontWeight: 900,
    fontVariant: "small-caps",
    letterSpacing: "2px",
    color: "#FF6000",
  },
}));

const AllPosts = () => {
  const classes = useStyles();
  const history = useHistory();
  const [postsArr, setPostsArr] = useState([]);
  const [categoryArr, setCategoryArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/posts`, {services: []})
      .then((res) => {
        // console.log(res.data);
        const allPost = res.data.post;
        setPostsArr(allPost);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/categories`)
      .then((res) => {
        // console.log(res.data);
        const allcategories = res.data.category;
        console.log(allcategories);
        setCategoryArr(allcategories);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const columns = [
    {
      name: "Title",
      field: "postTitle",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Category",
      field: "parentCategoryId",
      options: {
        filter: true,
        sort: false,
        customBodyRender: value => {
          let resultString = '';
          categoryArr.forEach(category => {
            if (category._id === value.parentCategoryId) {
              resultString = category.categoryName;
            }
          });
          return resultString;
        }
      },
    },
    {
      name: "Sub Category",
      field: "categoryId",
      options: {
        filter: true,
        sort: false,
        customBodyRender: value => {
          let resultString = '';
          categoryArr.forEach(category => {
            if (category._id === value.categoryId) {
              resultString = category.categoryName;
            }
          });
          return resultString;
        }
      },
    },
    {
      name: "Status",
      field: "status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Author",
      field: "author",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Order",
      field: "order",
      options: { filter: true, sort: true}
    },
    {
      name: "Date",
      field: "date",
      options: { filter: true, sort: true, customBodyRender: value =>
          moment(new Date(value.date)).format(SPACED_DATE_FORMAT) },
    },
    {
      name: "Actions",
      field: "action",
      options: {
        customBodyRender: value => {
          value.postPushNotificationTitle = '';
          value.postPushNotificationMessage = '';
          return (
            <>
              <Button
                style={{height: "30px"}}
                variant="outlined"
                color="primary"
                onClick={() => {
                  history.push({pathname: "/add-new-post", state: value});
                }}
              >
                Edit
              </Button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    sortColumnIndex: 6,
    sortColumnDirection: "desc",
    onRowsDelete: (rowsDeleted) => {
      const array = rowsDeleted.data;
      array.forEach(({ rowIndex, dataIndex }) => {
        const post_id = postsArr[dataIndex]._id;
        axios
          .post(`${process.env.REACT_APP_BASE_URL}api/posts/delete-post/${post_id}`)
          .then((res) => {
            console.log("Post Successfully deleted");
          });
      });
    },
  };

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTypography: {
          root: {
            fontFamily: "Montserrat !Important",
            color: "#000",
            fontSize: 20,
            fontWeight: 700,
            fontVariant: "small-caps",
            letterSpacing: "2px",
          },
        },
        MuiTableCell: {
          root: {
            fontSize: 16,
            fontVariant: "small-caps",
            fontFamily: "Montserrat !Important",
          },
        },
        MaterialDatatableToolbar: {
          root: {
            fontFamily: "Montserrat !Important",
            background: "#F2F2F2",
          },
        },

        MuiTableHead: {
          root: {
            fontWeight: 700,
            fontSize: 16,
          },
        },
        MaterialDatatableToolbarSelect: {
          deleteIcon: {
            color: "red",
          },
          title: {
            color: "#000 !important",
          },
        },

        MuiIconButton: {
          root: {
            color: "#2B2828",
          },
        },
      },
    });

  return (
    <>
      <div className={classes.root}>
        <NavDrawer />
        <div className={classes.content}>
          <div className={classes.main}>
            <Typography className={classes.allPostTitle} variant="h4">
              All Post Management
            </Typography>
            <Button
              variant="contained"
              size="large"
              className={classes.addBtn}
              onClick={() => {
                history.push("/add-new-post");
              }}
            >
              ADD NEW POST
            </Button>
          </div>
          <div className={classes.main}>
            <div
              style={{
                width: "100%",
                height: "auto",
              }}
            >
              <MuiThemeProvider theme={getMuiTheme()}>
                <MaterialDatatable
                  title={isLoading ? "Loading all post..." : "All Post"}
                  data={postsArr}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AllPosts;
