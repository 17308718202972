import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Grow,
  makeStyles,
  createStyles,
  InputAdornment,
} from "@material-ui/core";
import Hospital from "../assets/images/diabeat_it_web_portal_background.jpeg";
import Logo from "../assets/images/logo.png";
import "../assets/css/common.css";
import { useHistory, useParams } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import axios from "axios";

// styles
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        top: 0,
        left: 0,
      },
    },
    logotypeContainer: {
      backgroundImage: `url(${Hospital})`,
      backgroundSize: "cover",
      backgroundRepeat: "no repeat",
      width: "60%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "50%",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    logotypeText: {
      color: "white",
      fontWeight: 500,
      fontSize: 84,
      [theme.breakpoints.down("md")]: {
        fontSize: 48,
      },
    },
    formContainer: {
      width: "40%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "60%",
      },
    },
    form: {
      width: 320,
      padding: 24,
      justifyContent: "center",
      alignItems: "center",
      background: "#F1F1F1",
      borderRadius: 12,
      marginTop: 24,
      // [theme.breakpoints.down("md")]: {
      //   marginTop: 12,
      //   background: "#f8f9fa",
      //   borderRadius: 0,
      // },
    },
    greeting: {
      fontWeight: 500,
      marginTop: theme.spacing(4),
      color: "#FF6B00",
      letterSpacing: 3,
      fontSize: 24,
    },
    subGreeting: {
      fontWeight: 500,
      textAlign: "center",
      marginTop: theme.spacing(2),
    },
    title: {
      color: "rgb(54, 86, 127)",
      fontWeight: "700",
      letterSpacing: 1,
      fontSize: 24,
      marginBottom: 8,
    },
    subTitle: {
      color: "rgb(54, 86, 127)",
      letterSpacing: 1,
      fontSize: 16,
      marginBottom: 8,
      padding: "20px 0px 0px 0px",
      fontWeight: "bold",
    },
    contentText: {
      color: "rgb(54, 86, 127)",
      letterSpacing: 1,
      fontSize: 14,
      marginBottom: 8,
      padding: "10px 0px 0px 0px",
    },
    errorMessage: {
      textAlign: "center",
      fontSize: 12,
      fontWeight: "600",
      color: "red",
      padding: "20px 0px 0px 0px",
    },
    textFieldUnderline: {
      "&:before": {
        borderBottomColor: "#747272",
      },
      "&:after": {
        borderBottomColor: "rgb(54, 86, 127)",
      },
      "&:hover:before": {
        borderBottomColor: "rgb(54, 86, 127) !important",
      },
    },
    textField: {
      borderBottomColor: "#DC3545",
    },
    formButton: {
      width: "100%",
      marginTop: theme.spacing(4),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    copyright: {
      bottom: theme.spacing(4),
      paddingBottom: 4,
      marginTop: 24,
      color: "#2B2828",
      whiteSpace: "nowrap",
      textAlign: "center",
      position: "absolute",
    },
    saveBtn: {
      backgroundColor: "#FF6B00",
      color: "#FFF",
      textTransform: "none !Important",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#365680",
      },
    },
    passErrMessage: {
      fontSize: 12,
      fontWeight: "600",
      color: "red",
      padding: "5px 0px 0px 0px",
    },
    passFieldIcons: {
      height: 24,
      width: 24,
      cursor: "pointer",
      color: "#FF6B00",
    },
  })
);

const NewPassword = () => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { id, t } = useParams();

  const [user, setUser] = useState({
    userId: id,
    password: "",
    confirmPassword: "",
    authenticationToken: t,
  });

  const showPass = (e) => {
    setShowPassword(!showPassword);
  };

  const showConfirmPass = (e) => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const saveNewPass = async () => {
    console.log("USER IS ", user);

    if (user.password === user.confirmPassword) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/users/newpassword`,
          user
        );
        console.log(response);

        switch (response.status) {
          case 201:
            console.log("New password saved successfully");
            history.push("/login");
            break;
          case 409:
            alert(response.data.errors);
            break;
          case 500:
            alert(response);
            break;
          case 400:
            alert(response);
            break;
          case 202:
            alert(response);
            break;
          default:
            break;
        }
      } catch (error) {
        console.log("New password not saved successfully");
        const errorMessage = error;
        alert(errorMessage);
      }

      // fetch(`${process.env.REACT_APP_BASE_URL}api/users/newpassword`, {
      //   method: "POST",
      //   headers: {
      //     "Content-type": "application/json",
      //   },
      //   body: JSON.stringify(user),
      // })
      //   .then((result) => {
      //     console.log("New password saved successfully");
      //     console.log(result.json());
      //     history.push("/login");
      //   })
      //   .catch((err) => {
      //     console.log("New password not saved successfully");
      //     console.log(err);
      //     setError(true);
      //   });
    } else {
      // console.log("Passwords are not matching");
      setError(true);
    }
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}></div>
      <div className={classes.formContainer}>
        <img src={Logo} className="logo" alt="logo" />
        <form autoComplete={"off"} className={classes.form}>
          <Typography className={classes.title} variant="h3">
            New Password
          </Typography>
          <Typography className={classes.subTitle}>
            Please enter your new password below and also confirm it.
          </Typography>
          <Typography className={classes.contentText}>
            Strong password is required, Use uppercase letters, lowercase
            letters, numbers and symbols.
          </Typography>
          <>
            <TextField
              id="password"
              name="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword === true ? (
                      <VisibilityOffIcon
                        onClick={showPass}
                        className={classes.passFieldIcons}
                      />
                    ) : (
                      <VisibilityIcon
                        onClick={showPass}
                        className={classes.passFieldIcons}
                      />
                    )}
                  </InputAdornment>
                ),
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={user.password}
              onChange={(e) => {
                setUser({ ...user, password: e.target.value.trim() });
                console.log(user);
              }}
              margin="normal"
              placeholder="New Password"
              type={showPassword ? "text" : "password"}
              fullWidth
            />
            <TextField
              id="confirmPass"
              name="confirmPass"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showConfirmPassword === true ? (
                      <VisibilityOffIcon
                        onClick={showConfirmPass}
                        className={classes.passFieldIcons}
                      />
                    ) : (
                      <VisibilityIcon
                        onClick={showConfirmPass}
                        className={classes.passFieldIcons}
                      />
                    )}
                  </InputAdornment>
                ),
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={user.confirmPassword}
              onChange={(e) =>
                setUser({ ...user, confirmPassword: e.target.value.trim() })
              }
              margin="normal"
              placeholder="Confirm New Password"
              type={showConfirmPassword ? "text" : "password"}
              fullWidth
            />
            {user.password !== user.confirmPassword && (
              <Grow in={user.password !== user.confirmPassword}>
                <Typography className={classes.passErrMessage}>
                  Passwords must be same
                </Typography>
              </Grow>
            )}
            <div className={classes.formButton}>
              <Button
                disabled={
                  user.password.length === 0 ||
                  user.confirmPassword.length === 0 ||
                  user.password !== user.confirmPassword
                }
                className={classes.saveBtn}
                fullWidth
                variant="contained"
                size="large"
                disableTouchRipple={true}
                disableFocusRipple={true}
                onClick={saveNewPass}
              >
                Save
              </Button>
            </div>
            {error && (
              <Grow in={error}>
                <Typography className={classes.errorMessage}>
                  Something went wrong!!!
                </Typography>
              </Grow>
            )}
            {error && (
              <Grow in={error}>
                <Typography className={classes.errorMessage}>
                  New password not saved successfully.
                </Typography>
              </Grow>
            )}
          </>
        </form>
        <Typography color="primary" className={classes.copyright}>
          diabeat-it-app.ca © {new Date().getFullYear()}
        </Typography>
      </div>
    </Grid>
  );
};

export default NewPassword;
