import React, {useEffect, useState} from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Fade,
  Grow,
  makeStyles,
  createStyles,
  InputAdornment,
} from "@material-ui/core";
import Hospital from "../assets/images/diabeat_it_web_portal_background.jpeg";
import Logo from "../assets/images/diabeat-it-app-logo.png";
import "../assets/css/common.css";
import { useHistory } from "react-router";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import axios from "axios";

// styles
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        top: 0,
        left: 0,
      },
    },
    logotypeContainer: {
      backgroundImage: `url(${Hospital})`,
      backgroundSize: "cover",
      backgroundRepeat: "no repeat",
      width: "60%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "50%",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    logotypeText: {
      color: "white",
      fontWeight: 500,
      fontSize: 84,
      [theme.breakpoints.down("md")]: {
        fontSize: 48,
      },
    },
    formContainer: {
      width: "40%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "60%",
      },
    },
    form: {
      width: 320,
      padding: 24,
      justifyContent: "center",
      alignItems: "center",
      background: "#F1F1F1",
      borderRadius: 12,
      marginTop: 24,
    },
    greeting: {
      fontWeight: 500,
      marginTop: theme.spacing(4),
      color: "#FF6B00",
      letterSpacing: 3,
      fontSize: 24,
    },
    subGreeting: {
      fontWeight: 500,
      textAlign: "center",
      marginTop: theme.spacing(2),
    },
    title: {
      color: "#36567F",
      fontWeight: "700",
      letterSpacing: 1,
      fontSize: 24,
      marginBottom: 8,
    },
    errorMessage: {
      textAlign: "center",
      fontSize: 12,
      fontWeight: "600",
      color: "red",
      padding: "20px 0px 0px 0px",
    },
    textFieldUnderline: {
      "&:before": {
        borderBottomColor: "#747272",
      },
      "&:after": {
        borderBottomColor: "#36567F",
      },
      "&:hover:before": {
        borderBottomColor: "#36567F !important",
      },
    },
    textField: {
      borderBottomColor: "#DC3545",
    },
    formButtons: {
      width: "100%",
      marginTop: theme.spacing(4),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    forgetBtnContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px 0px 0px 0px",
    },
    forgetButton: {
      textTransform: "none",
      fontWeight: 400,
      color: "#FF6B00",
      display: "contents",
    },
    copyright: {
      bottom: theme.spacing(4),
      paddingBottom: 4,
      marginTop: 24,
      color: "#2B2828",
      whiteSpace: "nowrap",
      textAlign: "center",
      position: "absolute",
    },
    loginBtn: {
      backgroundColor: "#FF6B00",
      color: "#FFF",
      textTransform: "none !Important",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#365680",
      },
    },
    // signedUpContainer: {
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   padding: "20px 0px 0px 0px",
    // },
    // signedUpBtn: {
    //   textTransform: "none",
    //   fontWeight: 400,
    //   color: "#FF6B00",
    //   display: "contents",
    // },
    fieldIcons: {
      height: 24,
      width: 24,
      cursor: "none",
      pointerEvents: "none",
      color: "#FF6B00",
    },
    passErrMessage: {
      fontSize: 12,
      fontWeight: "600",
      color: "red",
      padding: "5px 0px 0px 0px",
    },
    passFieldIcons: {
      height: 24,
      width: 24,
      cursor: "pointer",
      color: "#FF6B00",
    },
  })
);

const Login = () => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const history = useHistory();
  const [emailValid, setEmailValid] = useState(true);
  const [showEmailValidity, setShowEmailValidity] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const emailValue = (e) => {
    let trimmedValue = e.target.value.trim();
    setUser({ ...user, email: trimmedValue });
    // console.log(trimmedValue);
    const validateEmail = (email) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    };

    if (validateEmail(trimmedValue) === true && trimmedValue !== "") {
      //console.log("trueeeee");

      setEmailValid(true);
    } else {
      //console.log("falseeeeeeeee");

      setEmailValid(false);
    }
  };

  const showPass = (e) => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/auth/login`,
        user
      );

      switch (response.status) {
        case 201:
          alert(response);
          break;
        case 409:
          alert(response.data.errors);
          break;
        case 500:
          alert(response);
          break;
        case 400:
          alert(response);
          break;
        case 202:
          console.log(response.data.user);
          localStorage.setItem('email', response.data.user.email);
          localStorage.setItem('token', response.data.token);
          history.push("/dashboard");
          break;
        default:
          break;
      }
    } catch (error) {
      console.log("Login failed ", error);
      // const errorMessage = error.res.data.errors[0].msg;
      const errorMessage = error;
      alert(errorMessage);
    }
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}></div>
      <div className={classes.formContainer}>
        <img src={Logo} style={{height: 66}} className="logo" alt="logo" />
        <form autoComplete={"off"} className={classes.form}>
          <Typography className={classes.title} variant="h3">
            Login
          </Typography>
          <>
            <Typography className={classes.greeting}>Welcome Admin</Typography>

            <TextField
              id="email"
              name="email"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showEmailValidity ? (
                      emailValid === true ? (
                        <Fade in={emailValid}>
                          <CheckIcon className={classes.fieldIcons} />
                        </Fade>
                      ) : (
                        <Fade in={!emailValid}>
                          <CloseIcon className={classes.fieldIcons} />
                        </Fade>
                      )
                    ) : (
                      ""
                    )}
                  </InputAdornment>
                ),
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={user.email}
              onChange={(e) => {
                // setUser({ ...user, email: e.target.value.trim() });
                emailValue(e);
              }}
              onFocus={() => {
                setShowEmailValidity(false);
              }}
              onBlur={(e) => {
                if (e.target.value.length > 0) {
                  setShowEmailValidity(true);
                } else {
                  setShowEmailValidity(false);
                }
              }}
              margin="normal"
              placeholder="Email Address"
              type="email"
              fullWidth
            />
            <TextField
              id="password"
              name="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword === true ? (
                      <VisibilityOffIcon
                        onClick={showPass}
                        className={classes.passFieldIcons}
                      />
                    ) : (
                      <VisibilityIcon
                        onClick={showPass}
                        className={classes.passFieldIcons}
                      />
                    )}
                  </InputAdornment>
                ),
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={user.password}
              onChange={(e) =>
                setUser({ ...user, password: e.target.value.trim() })
              }
              margin="normal"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              fullWidth
            />
            <div className={classes.formButtons}>
              <Button
                disabled={
                  user.email.length === 0 ||
                  user.password.length === 0 ||
                  emailValid !== true
                }
                className={classes.loginBtn}
                fullWidth
                variant="contained"
                size="large"
                disableTouchRipple={true}
                disableFocusRipple={true}
                onClick={handleLogin}
              >
                Login
              </Button>
            </div>
            {error && (
              <Grow in={error}>
                <Typography className={classes.errorMessage}>
                  Invalid Login Details
                </Typography>
              </Grow>
            )}
            <div className={classes.forgetBtnContainer}>
              <Button
                color="primary"
                size="large"
                className={classes.forgetButton}
                disableTouchRipple={true}
                disableFocusRipple={true}
                onClick={() => {
                  history.push("/reset-password");
                }}
              >
                Forget Password ?
              </Button>
            </div>
            {/* <div className={classes.signedUpContainer}>
              <Button
                color="primary"
                size="large"
                className={classes.signedUpBtn}
                disableTouchRipple={true}
                disableFocusRipple={true}
                onClick={() => {
                  history.push("/");
                }}
              >
                Not registered ? Sign Up
              </Button>
            </div> */}
          </>
        </form>
        <Typography color="primary" className={classes.copyright}>
          diabeat-it-app.ca © {new Date().getFullYear()}
        </Typography>
      </div>
    </Grid>
  );
};

export default Login;
