import React, { useState } from "react";
import clsx from "clsx";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import PeopleIcon from "@material-ui/icons/People";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "../assets/css/common.css";
import { LocalActivity } from "@material-ui/icons";
import Logo from "../assets/images/diabeat-it-app-logo.png";

const drawerWidth = 380;

const theme = createMuiTheme({
  typography: {
    // Tell Material-UI what's the font-family on the html element is.
    fontFamily: "Montserrat",
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#F2F2F2",
    color: '#000'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#F2F2F2",
    color: '#000'
  },
  menuButton: {
    marginRight: 36,
    color: "#3C3A39",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: "#365680",
    color: "#FFF",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#365680",
    color: "#FFF",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    backgroundColor: "#365680",
    // backgroundColor: "#FF6B00",
    color: "#FFF",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    color: "#FFF",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  icons: {
    color: "#FFF",
  },
  lsitItem: {
    height: "60px !Important",
  },
  listItemText: {
    letterSpacing: "1px !Important",
  },
  logOutBtn: {
    float: "right",
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  toolBarBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  customTooltip: {
    padding: 10,
    fontSize: 15,
  },
}));

const NavDrawer = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolBar}>
          <div className={classes.toolBarBox}>
            <Tooltip
              classes={{ tooltip: classes.customTooltip }}
              title="Menu"
              placement="right-end"
            >
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <img src={Logo} style={{width: 112, height: 33, marginRight: 12}} alt="logo" />
          </div>

          {/*<PowerSettingsNewIcon*/}
          {/*  className={classes.logOutBtn}*/}
          {/*  onClick={() => {*/}
          {/*    history.push("/login");*/}
          {/*  }}*/}
          {/*/>*/}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          // when open in phone screen
          //[classes.hide]: !open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon className={classes.icons} />
            ) : (
              <ChevronLeftIcon className={classes.icons} />
            )}
          </IconButton>
        </div>
        <Divider />
        <List className={classes.list}>
          <ListItem
            button
            onClick={() => {
              history.push("/dashboard");
            }}
            className={classes.lsitItem}
          >
            <Tooltip
              classes={{ tooltip: classes.customTooltip }}
              title="Dashboard"
              placement="right-end"
            >
              <ListItemIcon>
                <DashboardIcon className={classes.icons} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText className={classes.listItemText}>
              Dashboard
            </ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              history.push("/all-posts");
            }}
            className={classes.lsitItem}
          >
            <Tooltip
              classes={{ tooltip: classes.customTooltip }}
              title="All Post"
              placement="right-end"
            >
              <ListItemIcon>
                <DynamicFeedIcon className={classes.icons} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText className={classes.listItemText}>
              All Post
            </ListItemText>
          </ListItem>
          {/*<ListItem*/}
          {/*  button*/}
          {/*  onClick={() => {*/}
          {/*    history.push("/hospitals");*/}
          {/*  }}*/}
          {/*  className={classes.lsitItem}*/}
          {/*>*/}
          {/*  <Tooltip*/}
          {/*    classes={{ tooltip: classes.customTooltip }}*/}
          {/*    title="Base Hospital Management"*/}
          {/*    placement="right-end"*/}
          {/*  >*/}
          {/*    <ListItemIcon>*/}
          {/*      <LocalHospitalIcon className={classes.icons} />*/}
          {/*    </ListItemIcon>*/}
          {/*  </Tooltip>*/}
          {/*  <ListItemText className={classes.listItemText}>*/}
          {/*    Base Hospital Management*/}
          {/*  </ListItemText>*/}
          {/*</ListItem>*/}
          {/*<ListItem*/}
          {/*  button*/}
          {/*  onClick={() => {*/}
          {/*    history.push("/services");*/}
          {/*  }}*/}
          {/*  className={classes.lsitItem}*/}
          {/*>*/}
          {/*  <Tooltip*/}
          {/*    classes={{ tooltip: classes.customTooltip }}*/}
          {/*    title="Service Management"*/}
          {/*    placement="right-end"*/}
          {/*  >*/}
          {/*    <ListItemIcon>*/}
          {/*      <LocalActivity className={classes.icons} />*/}
          {/*    </ListItemIcon>*/}
          {/*  </Tooltip>*/}
          {/*  <ListItemText className={classes.listItemText}>*/}
          {/*    Services Management*/}
          {/*  </ListItemText>*/}
          {/*</ListItem>*/}
          <ListItem
            button
            onClick={() => {
              history.push("/categories");
            }}
            className={classes.lsitItem}
          >
            <Tooltip
              classes={{ tooltip: classes.customTooltip }}
              title="Category Management"
              placement="right-end"
            >
              <ListItemIcon>
                <AccountTreeIcon className={classes.icons} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText className={classes.listItemText}>
              Category Management
            </ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              history.push("/push-notifications");
            }}
            className={classes.lsitItem}
          >
            <Tooltip
              classes={{ tooltip: classes.customTooltip }}
              title="Push Notification Management"
              placement="right-end"
            >
              <ListItemIcon>
                <NotificationsIcon className={classes.icons} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText className={classes.listItemText}>
              Push Notification Management
            </ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              history.push("/comments");
            }}
            className={classes.lsitItem}
          >
            <Tooltip
              classes={{ tooltip: classes.customTooltip }}
              title="Feedback Management"
              placement="right-end"
            >
              <ListItemIcon>
                <ThumbsUpDownIcon className={classes.icons} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText className={classes.listItemText}>
              Feedback Management
            </ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              history.push("/users");
            }}
            className={classes.lsitItem}
          >
            <Tooltip
              classes={{ tooltip: classes.customTooltip }}
              title="User Management"
              placement="right-end"
            >
              <ListItemIcon>
                <PeopleIcon className={classes.icons} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText className={classes.listItemText}>
              User Management
            </ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              history.push("/login");
            }}
            className={classes.lsitItem}
          >
            <Tooltip
              classes={{ tooltip: classes.customTooltip }}
              title="Log Out"
              placement="right-end"
            >
              <ListItemIcon>
                <PowerSettingsNewIcon className={classes.icons} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText className={classes.listItemText}>
              Log Out
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default NavDrawer;
