import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Fade,
  Grow,
  makeStyles,
  createStyles,
  CircularProgress,
} from "@material-ui/core";
import Hospital from "../assets/images/diabeat_it_web_portal_background.jpeg";
import Logo from "../assets/images/logo.png";
import "../assets/css/common.css";
import { useHistory, useParams } from "react-router-dom";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ReportProblemRoundedIcon from "@material-ui/icons/ReportProblemRounded";
import axios from "axios";

// styles
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        top: 0,
        left: 0,
      },
    },
    logotypeContainer: {
      backgroundImage: `url(${Hospital})`,
      backgroundSize: "cover",
      backgroundRepeat: "no repeat",
      width: "60%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "50%",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    formContainer: {
      width: "40%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "60%",
      },
    },
    form: {
      width: 320,
      padding: 24,
      justifyContent: "center",
      alignItems: "center",
      background: "#F1F1F1",
      borderRadius: 12,
      marginTop: 24,
      // [theme.breakpoints.down("md")]: {
      //   marginTop: 12,
      //   background: "#f8f9fa",
      //   borderRadius: 0,
      // },
    },
    title: {
      color: "#36567F",
      fontWeight: "700",
      letterSpacing: 1,
      fontSize: 24,
      marginBottom: 8,
    },
    errorMessage: {
      textAlign: "center",
      fontWeight: "600",
      color: "red",
      letterSpacing: 1,
      marginBottom: 8,
      padding: "20px 0px 20px 0px",
    },
    copyright: {
      bottom: theme.spacing(4),
      paddingBottom: 4,
      marginTop: 24,
      color: "#2B2828",
      whiteSpace: "nowrap",
      textAlign: "center",
      position: "absolute",
    },
    loginBtn: {
      backgroundColor: "#FF6B00",
      color: "#FFF",
      textTransform: "none !Important",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#365680",
      },
    },
    subTitle: {
      color: "#36567F",
      letterSpacing: 1,
      marginBottom: 8,
      padding: "20px 0px 20px 0px",
    },
    circularProgress: {
      padding: "10px 0px",
      color: "#FF6B00",
    },
    verifiedIcon: {
      padding: "10px 0px",
      color: "#FF6B00",
      fontSize: 100,
    },
    iconHolder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    errorIcon: {
      padding: "10px 0px",
      color: "red",
      fontSize: 100,
    },
  })
);

const Verification = () => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [success, setSucess] = useState(false);
  const history = useHistory();
  const { id, t } = useParams();
  const [user, setUser] = useState({
    userId: id,
    authenticationToken: t,
  });
  console.log("id is ", id);
  console.log("tokesn is ", t);

  useEffect(() => {
    console.log("USER BEFORE RESPONSE ", user);

    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/users/verify`, user)
      .then((res) => {
        switch (res.status) {
          case 201:
            console.log("USER AFTER RESPONSE IS ", user);
            console.log(res);
            console.log("Verification successful");
            setSucess(true);
            break;
          case 409:
            alert(res.data.errors);
            break;
          case 500:
            alert(res);
            break;
          case 400:
            alert(res);
            break;
          case 202:
            alert(res);
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        console.log("Verification failed");
        setSucess(null);
        setError(true);
        const errorMessage = error;
        alert(errorMessage);
      });

    // fetch(`${process.env.REACT_APP_BASE_URL}api/users/verify`, {
    //   method: "GET",
    //   headers: {
    //     "Content-type": "application/json",
    //   },
    //   body: JSON.stringify(user),
    // })
    //   .then((result) => {
    //     setSucess(true);
    //     console.log(result.json());
    //     console.log("Verification successful");
    //   })
    //   .catch((err) => {
    //     console.log("Verification failed");
    //     console.log(err);
    //     setSucess(null);
    //     setError(true);
    //   });
  }, []);

  const handleLogin = () => {
    history.push("/login");
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}></div>
      <div className={classes.formContainer}>
        <img src={Logo} className="logo" alt="logo" />

        <form autoComplete={"off"} className={classes.form}>
          <Typography className={classes.title}>Verification</Typography>

          {success === false && (
            <Fade>
              <>
                <div className={classes.iconHolder}>
                  <CircularProgress
                    size={70}
                    className={classes.circularProgress}
                  />
                  <Typography className={classes.subTitle}>
                    Verification is under process
                  </Typography>
                </div>
              </>
            </Fade>
          )}

          {success === true && (
            <Fade>
              <>
                <div className={classes.iconHolder}>
                  <VerifiedUserIcon className={classes.verifiedIcon} />
                  <Typography className={classes.subTitle}>
                    Verification successful
                  </Typography>
                </div>
                <Button
                  className={classes.loginBtn}
                  fullWidth
                  variant="contained"
                  size="large"
                  disableTouchRipple={true}
                  disableFocusRipple={true}
                  onClick={handleLogin}
                >
                  Login
                </Button>
              </>
            </Fade>
          )}

          {error && (
            <Grow in={error}>
              <>
                <div className={classes.iconHolder}>
                  <ReportProblemRoundedIcon className={classes.errorIcon} />
                  <Typography className={classes.errorMessage}>
                    Verification Failed !!
                  </Typography>
                </div>
              </>
            </Grow>
          )}
        </form>

        <Typography className={classes.copyright}>
          diabeat-it-app.ca © {new Date().getFullYear()}
        </Typography>
      </div>
    </Grid>
  );
};

export default Verification;
